import styled from "styled-components";
import React from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { coursePath } from "../constants";

const PageTitle = styled.h1`
  color: #ff5c20;
  font-size: 36px;
  font-weight: 600;
  line-height: 52px;
  margin: 0 0 10px;
`;
const Dot = styled.span`
  background-color: #ff5c20;
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  position: relative;
  margin: 2px 8px;
`;
const StyledNav = styled.nav`
  font-size: 13px;
  font-family: "Roboto", sans-serif;
  background-image: url("https://www.toxicology.org/images/_interior-banners/About-banner.jpg");
  background-size: cover;
  background-position-x: center;
  margin-bottom: 50px;
`;
const Container = styled.div`
  padding: 50px 15px;
  max-width: 1170px;
  text-align: left;
`;
const Items = styled.div`
  display: inline-block;
  list-style-type: decimal;
  padding: 0px;
  color: white;
`;
const Item = styled.span`
  color: white;
  padding: 8px 0;
  margin-bottom: 20px;
  list-style: none;
  display: inline-block;
  font-size: 14px;
`;
const ActiveItem = styled.span`
  color: white;
  padding: 8px 0;
  margin-bottom: 20px;
  list-style: none;
  display: inline-block;
  font-size: 14px;
  font-weight: bold;
`;

function BreadCrumb() {
  const { pathname } = useLocation();
  const isLoginPage = (pathname && pathname.startsWith("/login")) || false;

  let pageTitle = "Society of Toxicology (SOT)";
  let meta = null;
  let title;
  let folderTitles = [];
  if (isLoginPage) {
    title = "Login";
  } else if (pathname.startsWith("/book")) {
    title = "Continuing Education Courses";
  } else if (pathname.startsWith(`/${coursePath}`)) {
    folderTitles = ["Education"];
    title = "CEd-Tox: Continuing Education Courses Online";
    pageTitle =
      "CEd-Tox: Continuing Education Courses Online - Society of Toxicology";
    meta = [
      <meta key="1" charSet="utf-8" />,
      <meta
        key="2"
        name="description"
        content="SOT CEd-Tox, the Society’s Continuing Education (CE) online courses program, provides online training in basic and advanced toxicology topics."
      />,
      <meta
        key="3"
        name="keywords"
        content="CEd-Tox, education, online, courses, CE program, CE, continuing education, online continuing education, online CE"
      />,
      <meta
        key="4"
        name="viewport"
        content="width=device-width, initial-scale=1.0 shrink-to-fit=no"
      />,
      <meta key="5" name="author" content="Society of Toxicology" />,
      <meta key="6" name="robots" content="index" />,
      <meta key="7" property="fb:app_id" content="309282950004135" />,
      <meta
        key="8"
        property="og:title"
        content="CEd-Tox: Continuing Education Courses Online - Society of Toxicology"
      />,
      <meta
        key="9"
        property="og:description"
        content="SOT CEd-Tox, the Society’s Continuing Education (CE) online courses program, provides online training in basic and advanced toxicology topics."
      />,
      <meta
        key="10"
        property="og:image"
        content="http://www.toxicology.org/images/sot_logo.png"
      />,
      <meta
        key="11"
        property="og:url"
        content="https://www.toxicology.org/education/ce/onlineCourses.asp"
      />,
      <meta key="12" property="og:type" content="website" />,
      <meta key="13" property="og:site_name" content="Society of Toxicology" />,
      <meta key="14" name="twitter:card" content="summary_large_image" />,
      <meta key="15" name="twitter:image:alt" content="SOT logo" />,
      <meta key="16" name="twitter:creator" content="@SOToxicology" />,
      <meta key="17" name="twitter:site" content="@SOToxicology" />,
    ];
  } else if (pathname.startsWith("/ballot")) {
    title = "Official Ballot 2023–2024";
  } else if (pathname.indexOf("/admin") >= 0) {
    title = "Admin";
  } else if (pathname.startsWith(`/recipients2024`)) {
    folderTitles = ["Awards & Funding"];
    title = "Recent Endowment Fund Award Recipients";
    meta = [
      <meta key="1" charSet="utf-8" />,
      <meta
        key="2"
        name="description"
        content="Most of the SOT Endowment Funds sponsor awards designed to encourage toxicology research and toxicologists. Meet the recipients of the 2024 Endowment Fund Awards."
      />,
      <meta
        key="3"
        name="keywords"
        content="SOT Endowment Fund, Award, Award Recipient, Endowment Award, Endowment Fund Award, Endowment Fund Awardee, awardee, recipient, 2024 Endowment Fund, 2024 award"
      />,
      <meta
        key="4"
        name="viewport"
        content="width=device-width, initial-scale=1.0"
      />,
      <meta key="5" name="author" content="Society of Toxicology" />,
      <meta key="6" name="robots" content="index" />,
      <meta key="7" property="fb:app_id" content="309282950004135" />,
      <meta
        key="8"
        property="og:title"
        content="2024 Endowment Fund Award Recipients—Society of Toxicology"
      />,
      <meta
        key="9"
        property="og:description"
        content="Most of the SOT Endowment Funds sponsor awards designed to encourage toxicology research and toxicologists. Meet the recipients of the 2024 Endowment Fund Awards."
      />,
      <meta
        key="10"
        property="og:image"
        content="https://sot.toxicology.org/images/endowment-meta.jpg"
      />,
      <meta key="11" property="og:url" content="https://www.toxicology.org/endowment/Eawards/recipients2024.asp" />,
      <meta key="12" property="og:type" content="website" />,
      <meta
        key="13"
        property="og:site_name"
        content="Society of Toxicology (SOT)"
      />,
      <meta key="14" name="twitter:card" content="summary_large_image" />,
      <meta key="15" name="twitter:site" content="@SOToxicology" />,
      <meta
        key="16"
        name="twitter:image:alt"
        content="The background of the graphic is a dark blue. Centered in the graphic is the SOT Endowment Fund logo in white. The left portion of this logo consists of a crescent shape with the concave portion to the right. The crescent is stretched so that the curved portion of the body is very wide. The top point of the crescent is pulled to the right so that it stretches above the text portion of the logo. To the left edge of the white crescent is the SOT Salus, which is a circle shape containing a shield and text. The Salus is colored in gold and dark blue. The text that appears to the right of the crescent is the SOT logo in white, which consists of a capital S, O, T with words Society of Toxicology to the right of the capital SOT with Society of appearing on one line with Toxicology on the line below it with a thin, vertical line separating the capital SOT from the words Society of Toxicology. Below the SOT logo in italic all capitals font is the word ENDOWMENT. Below endowment is the phrase Investing in the Future…. Surround the logo are hexagon symbols alluding to chemical compound structures."
      />,
    ];
  } else if (pathname.startsWith(`/award`)) {
    folderTitles = ["Awards & Funding"];
    title = "Search for SOT Awards";
    meta = [
      <meta
        key="1"
        name="description"
        content="Search the SOT Awards database to find nomination or application information for the Society’s numerous award opportunities."
      />,
      <meta
        key="2"
        name="keywords"
        content="Awards, Honors, Award Search, Category, Audience, Student Awards, Endowment Fund Awards, SOT Award, Nomination, Application"
      />,
      <meta
        key="3"
        name="viewport"
        content="width=device-width, initial-scale=1.0 shrink-to-fit=no"
      />,
      <meta key="4" name="author" content="Society of Toxicology" />,
      <meta key="5" name="robots" content="index" />,
      <meta key="6" property="fb:app_id" content="309282950004135" />,
      <meta key="7" property="og:title" content="Search for SOT Awards" />,
      <meta
        key="8"
        property="og:description"
        content="Explore the nomination and application information for Society’s numerous award opportunities that recognize outstanding scientists across academic and career levels."
      />,
      <meta
        key="9"
        property="og:image"
        content="https://toxicology.smugmug.com/2024-SOT-Annual-Meeting-and-ToxExpo/Component-Group-Events-2024-SOT/Ocular-Toxicology-Specialty-Section-Reception-2024-SOT/i-fQP7Ctm/A"
      />,
      <meta key="10" property="og:url" content="http://www.toxicology.org/" />,
      <meta key="11" property="og:type" content="website" />,
      <meta
        key="12"
        property="og:site_name"
        content="Society of Toxicology (SOT)"
      />,
      <meta key="13" name="twitter:card" content="summary_large_image" />,
      <meta
        key="14"
        name="twitter:image:alt"
        content="Three individuals stand in a row facing the camera. Two of the individuals are displaying award certificates. All are smiling at the camera.”>"
      />,
      <meta key="15" name="twitter:creator" content="@SOToxicology" />,
      <meta key="16" name="twitter:site" content="@SOToxicology" />,
    ];
  } else if (pathname.startsWith(`/endowment`)) {
    folderTitles = ["Endowment Fund"];
    title = "Endowment Fund Search";
    meta = [
      <link key="0" rel="canonical" href="https://www.toxicology.org/" />,
      <meta key="1" charSet="utf-8" />,
      <meta
        key="2"
        name="description"
        content="Individuals can use this web page to search and sort all the SOT Endowment Funds to identify funds that match their interests and priorities for donation consideration."
      />,
      <meta
        key="3"
        name="keywords"
        content="SOT Endowment Fund, Search Funds, Search Endowment,
Find Endowment Fund, View Endowment Fund, Search Endowment Fund"
      />,
      <meta
        key="4"
        name="viewport"
        content="width=device-width, initial-scale=1.0"
      />,
      <meta key="5" name="author" content="Society of Toxicology" />,
      <meta key="6" name="robots" content="index" />,
      <meta key="7" property="fb:app_id" content="309282950004135" />,
      <meta
        key="8"
        property="og:title"
        content="SOT Endowment Fund—Endowment Fund Search"
      />,
      <meta
        key="9"
        property="og:description"
        content="Search and sort all the Endowment Funds to find the fund(s) that align with your interests and priorities for donation consideration."
      />,
      <meta
        key="10"
        property="og:image"
        content="http://www.toxicology.org/images/sot_logo.png"
      />,
      <meta key="11" property="og:url" content="www.toxicology.org" />,
      <meta key="12" property="og:type" content="website" />,
      <meta
        key="13"
        property="og:site_name"
        content="Society of Toxicology (SOT)"
      />,
      <meta key="14" name="twitter:card" content="summary_large_image" />,
      <meta key="15" name="twitter:site" content="@SOToxicology" />,
      <meta
        key="16"
        name="twitter:image:alt"
        content="The background of the graphic is a dark blue. Centered in the graphic is the SOT Endowment Fund logo in white. The left portion of this logo consists of a crescent shape with the concave portion to the right. The crescent is stretched so that the curved portion of the body is very wide. The top point of the crescent is pulled to the right so that it stretches above the text portion of the logo. To the left edge of the white crescent is the SOT Salus, which is a circle shape containing a shield and text. The Salus is colored in gold and dark blue. The text that appears to the right of the crescent is the SOT logo in white, which consists of a capital S, O, T with words Society of Toxicology to the right of the capital SOT with Society of appearing on one line with Toxicology on the line below it with a thin, vertical line separating the capital SOT from the words Society of Toxicology. Below the SOT logo in italic all capitals font is the word ENDOWMENT. Below endowment is the phrase Investing in the Future…. Surrounding the logo are hexagon symbols alluding to chemical compound structures."
      />,
    ];
  } else if (pathname.indexOf("/events/photos") >= 0) {
    title = "Photo Galleries";
    folderTitles = ["Meetings & Events"];
  } else {
    title = "Home";
  }

  return (
    <StyledNav>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        {meta}
      </Helmet>
      <div className="main-content">
        <Container className="container">
          <div className="section-content">
            <div className="row">
              <div className="col-md-6">
                <PageTitle>{title}</PageTitle>
                <Items>
                  <Item>Home</Item>
                  {folderTitles.map((folderTitle) => (
                    <React.Fragment key={folderTitle}>
                      <Dot />
                      <ActiveItem>{folderTitle}</ActiveItem>
                    </React.Fragment>
                  ))}
                  <Dot />
                  <ActiveItem>{title}</ActiveItem>
                </Items>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </StyledNav>
  );
}

export default BreadCrumb;
