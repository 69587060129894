import styled from "styled-components";
import React, { useState, useRef, useEffect } from "react";
import { blue, red } from "../../styles";
import AwardCard from "./AwardCard";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Page = styled.main`
  text-align: left;
  color: #3c3b3b;
`;
const SectionContent = styled.div``;
const Panel = styled.div`
  text-align: left;
`;
const Loading = styled.div`
  margin: 30px auto;
  font-size: 40px;
  text-align: center;
  color: ${red};
`;
const ButtonPanel = styled.div`
  text-align: center;
  margin: 0 10px;
  @media (min-width: 992px) {
    text-align: left;
  }
  @media print {
    display: none;
  }
`;
const IntroText = styled.p`
  text-align: left;
`;
const Button = styled.button`
  margin: 0 15px 10px 0;
`;
const CompleteStep = styled.div`
  margin: 30px 0 40px;
`;
const InfoLabel = styled.span`
  font-weight: bold;
  color: ${blue};
`;
const Warning = styled.span`
  color: gray;
`;

function Awardees() {
  const [awardeeList, setAwardeeList] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const topRef = useRef(null);

  useEffect(() => {
    fetch("/api/getMasterReport")
      .then((response) => response.json())
      .then((data) => {
        const sortedList = data.reduce((acc, awardee) => {
          if (acc[awardee.endowment_fund]) {
            acc[awardee.endowment_fund].awardeeList.push(awardee);
          } else {
            acc[awardee.endowment_fund] = {
              name: awardee.endowment_fund,
              awardeeList: [awardee],
            };
          }
          return acc;
        }, {});

        for (let awardId in sortedList) {
          sortedList[awardId].awardeeList.sort((a, b) => {
            return a.last_name.localeCompare(b.last_name);
          });
        }
        setAwardeeList(sortedList);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setError("Error fetching data", error);
      });
  }, []);

  if (isLoading) {
    return (
      <Page>
        <Loading>
          <FontAwesomeIcon icon={faSpinner} className="fa-spin" />
        </Loading>
      </Page>
    );
  } else if (error) {
    return (
      <span className="row">
        <span className="alert alert-danger" role="alert">
          {error}
        </span>
        <br />
      </span>
    );
  }

  return (
    <SectionContent>
      <div ref={topRef} />
      <h1
        style={{
          textAlign: "left",
          color: "rgb(0, 55, 103)",
          fontSize: "32px",
          fontWeight: "bold",
        }}
      >
        2024 Endowment Awardees
      </h1>
      <p style={{ textAlign: "left" }}>
        The SOT Endowment Fund is a family of funds that support SOT programs
        and members. Many of the funds sponsor awards designed to encourage,
        assist, and highlight toxicology research and toxicologists.
      </p>
      <Panel>
        <div className="row">
          {Object.keys(awardeeList).length &&
            Object.keys(awardeeList)
              .sort()
              .map((awardId, index) => (
                <AwardCard
                  key={`fund-${index}`}
                  awardName={awardeeList[awardId].name}
                  awardeeList={awardeeList[awardId].awardeeList}
                />
              ))}
        </div>
      </Panel>
    </SectionContent>
  );
}

export default Awardees;
