import React from "react";
import ReactDOM from "react-dom/client";
// import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import Ballot from "./components/ballot/Ballot";
import EventList from "./components/event/EventList";
import EndowmentList from "./components/endowment/EndowmentList";
import EndowmentListAdmin from "./components/endowment/EndowmentListAdmin";
import EndowmentAdmin from "./components/endowment/EndowmentAdmin";
import Book from "./components/Book";
import CoursePage from "./components/course/CoursePage";
import CourseAdmin from "./components/course/CourseAdmin";
import CourseListAdmin from "./components/course/CourseListAdmin";
import PhotoGallery from "./components/PhotoGallery";
import SMAdmin from "./components/smAdmin";
import Login from "./components/Login";
import Admin from "./components/Admin";
import App from "./App";
import AvolioTransfer from "./components/AvolioTransfer";
import reportWebVitals from "./reportWebVitals";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import { coursePath } from "./constants";
import Awardees from "./components/awardees";

const router = createBrowserRouter([
  {
    path: "avolio/transfer",
    element: <AvolioTransfer />,
  },
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "recipients2024",
        element: <Awardees />,
      },
      {
        path: "book",
        element: <Book />,
      },
      {
        path: `${coursePath}:course`,
        element: <CoursePage />,
      },
      {
        path: "ballot",
        element: <Ballot />,
      },
      {
        path: "ballot/23-24",
        element: <Ballot />,
      },
      {
        path: "ballot-preview/23-24",
        element: <Ballot preview={true} />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "admin",
        element: <Admin />,
      },
      {
        path: "smAdmin",
        element: <SMAdmin />,
      },
      {
        path: "admin/course/:course",
        element: <CourseAdmin />,
      },
      {
        path: "admin/course",
        element: <CourseListAdmin />,
      },
      {
        path: "award",
        element: <EventList />,
      },
      {
        path: "endowment",
        element: <EndowmentList />,
      },
      {
        path: "admin/endowment",
        element: <EndowmentListAdmin />,
      },
      {
        path: "admin/endowment/:endowmentId",
        element: <EndowmentAdmin />,
      },
      {
        path: "events/photos",
        element: <PhotoGallery />,
      },
      {
        path: "*",
        element: <Navigate to="/" replace />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
